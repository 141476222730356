<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <div class="card-title">Archivio ordini</div>
          </CCardHeader>
          <CCardBody>
            <TB :tb_config="tb">
              <e-columns>
                <e-column
                  field="id"
                  headerText="ID"
                  type="number"
                  :isPrimaryKey="true"
                  clipMode="EllipsisWithTooltip"
                  :is-identity="true"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="user.email"
                  headerText="Utente"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="uTemplate"
                >
                </e-column>
                <e-column
                  field="family.name"
                  headerText="Conto"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="fTemplate"
                >
                </e-column>
                <e-column
                  field="market.role.name"
                  headerText="Negozio"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="mTemplate"
                >
                </e-column>
                <e-column
                  field="effective_date"
                  headerText="Data ordine"
                  clipMode="EllipsisWithTooltip"
                  :allowEditing="false"
                  type="date"
                  :format="dtFormat"
                ></e-column>
                <e-column
                  field="view.tot_price"
                  type="number"
                  headerText="Importo"
                  clipMode="EllipsisWithTooltip"
                ></e-column>
                <e-column
                  field="view.tot_prods"
                  type="number"
                  headerText="Prodotti"
                  clipMode="EllipsisWithTooltip"
                ></e-column>
                <e-column
                  field="view.tot_status"
                  type="number"
                  headerText="Stato"
                  clipMode="EllipsisWithTooltip"
                  :template="sTemplate"
                ></e-column>
                <e-column
                  field="ddmi.island.role.name"
                  headerText="consegna/ritiro"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="iTemplate"
                >
                </e-column>
                <e-column
                  field="ts_created"
                  :headerText="$t('common.table.created')"
                  type="dateTime"
                  :format="tsFormat"
                  :allowEditing="false"
                  clipMode="EllipsisWithTooltip"
                ></e-column>
                <e-column
                  field="ts_modified"
                  :headerText="$t('common.table.last_modified')"
                  type="dateTime"
                  :format="tsFormat"
                  :allowEditing="false"
                  clipMode="EllipsisWithTooltip"
                ></e-column>
                <!--  <e-column
                  :field="
                    'ppl.productproducer.category.translations.' +
                    this.$i18n.locale +
                    '.name'
                  "
                  headerText="Categoria"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="ccTemplate"
                >
                </e-column>
                <e-column
                  field="ppl.productproducer.producer.role.name"
                  headerText="Produttore"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="rpTemplate"
                ></e-column>
                <e-column
                  :field="
                    'ppl.productproducer.translations.' +
                    this.$i18n.locale +
                    '.name'
                  "
                  headerText="Prodotto"
                  :allowEditing="false"
                  editType="textedit"
                  clipMode="EllipsisWithTooltip"
                  :template="ppTemplate"
                ></e-column>
                <e-column
                  v-bind:field="
                    'ppl.translations.' + this.$i18n.locale + '.name'
                  "
                  headerText="Variante"
                  type="string"
                  :allowEditing="false"
                  clipMode="EllipsisWithTooltip"
                  :template="pplTemplate"
                ></e-column>
                <e-column
                  field="ppm.price"
                  type="number"
                  :headerText="$t('models.ppm.price')"
                  clipMode="EllipsisWithTooltip"
                ></e-column>
                <e-column
                  field="ppm.flag"
                  :headerText="$t('models.ppm.flag')"
                  foreignKeyValue="label"
                  :dataSource="Flags"
                  foreignKeyField="value"
                  clipMode="EllipsisWithTooltip"
                ></e-column>
                <e-column
                  field="ppl.price_len"
                  type="number"
                  :headerText="$t('models.lot.price_len')"
                  clipMode="EllipsisWithTooltip"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="ppl.price_unit"
                  :headerText="$t('models.lot.price_unit')"
                  foreignKeyValue="label"
                  :dataSource="Units"
                  foreignKeyField="value"
                  clipMode="EllipsisWithTooltip"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="ppl.price_len_mol"
                  :headerText="$t('models.lot.price_len_mol')"
                  displayAsCheckBox="true"
                  type="boolean"
                  editType="booleanedit"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="ppl.pw"
                  :headerText="$t('models.lot.pw')"
                  displayAsCheckBox="true"
                  type="boolean"
                  editType="booleanedit"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="ppl.pw_len"
                  type="number"
                  :headerText="$t('models.lot.pw_len')"
                  clipMode="EllipsisWithTooltip"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="ppl.pw_unit"
                  :headerText="$t('models.lot.pw_unit')"
                  foreignKeyValue="label"
                  :dataSource="Units"
                  foreignKeyField="value"
                  clipMode="EllipsisWithTooltip"
                  :allowEditing="false"
                ></e-column> -->
              </e-columns>
            </TB>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import TB from "../../common/TB";
import { GetDataManager } from "../../ds/index";
import { DataManager } from "@syncfusion/ej2-data";

export default Vue.extend({
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("auth_orders", []),
        searchOptions: {
          fields: [
            // 'ppl.translations.' + this.$i18n.locale + '.name',
            "id",
          ],
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        },
        childGrid: {
          // dataSource: new DataManager({
          //   url:
          //     LOONITY_BASE_API_URL +
          //     "/api/producer/" +
          //     this.$store.state.role.id +
          //     "/productlots/",
          //   adaptor: new DrfAdaptor(),
          //   crossDomain: true,
          // }),
          dataSource: GetDataManager("auth_orders_details", []),
          queryString: "order_id",
          load: function () {
            this.parentDetails.parentKeyFieldValue =
              this.parentDetails.parentRowData["id"];
          },
          columns: [
            {
              field: "id",
              headerText: "ID",
            },
            {
              field: "productname",
              headerText: "prodotto",
            },
            {
              field: "productcomposition",
              headerText: "variante",
            },
            {
              field: "qta",
              headerText: "quantità",
            },
            {
              field: "price",
              headerText: "prezzo",
            },
            {
              field: "status",
              headerText: "stato",
              foreignKeyValue: "label",
              dataSource: this.oStatus,
              foreignKeyField: "value",
              clipMode: "EllipsisWithTooltip",
            },
            // {
            //   field: "translations." + this.$i18n.locale + ".lot_description",
            //   headerText: this.$t('models.lot.lot_description'),
            //   visible: false,
            // },
            // {
            //   field: "is_active",
            //   headerText: this.$t('models.lot.is_active'),
            //   displayAsCheckBox: "true",
            // },
            // { field: "availability",
            // headerText: this.$t('models.lot.availability'),
            // },
            // {
            //   field: "availability_type",
            //   headerText: this.$t('models.lot.availability_type'),
            //   visible: false,
            // },
            // { field: "producer_code",
            // headerText: this.$t('models.lot.producer_code'),
            //  },
            // { field: "expiry_date",
            // headerText: this.$t('models.lot.expiry_date'),
            // },
            // { field: "price",
            // headerText: this.$t('models.lot.price'),
            // },
            // { field: "price_sugg",
            // headerText: this.$t('models.lot.price_sugg'),
            // },
            // { field: "pw",
            // headerText: this.$t('models.lot.pw'),
            // displayAsCheckBox: "true" },
            // {
            //   field: "wh_product",
            //   headerText: this.$t('models.lot.wh_product'),
            //   displayAsCheckBox: "true",
            // },
            // { field: "valid_from",
            // headerText: this.$t('models.lot.valid_from'),
            // },
            // { field: "valid_to",
            // headerText: this.$t('models.lot.valid_to'),
            // },
            {
              field: "ts_created",
              headerText: this.$t("common.table.created"),
              format: this.tsFormat,
              visible: false,
            },
            {
              field: "ts_modified",
              headerText: this.$t("common.table.last_modified"),
              format: this.tsFormat,
              visible: false,
            },
          ],
        },
        // toolbar: ["Edit", "Update", "Cancel"],
      },
      // ProductData: GetDataManager("role_producer_products", [
      //   this.$store.state.role.id,
      // ]),
      oStatus: new DataManager([
        { value: 0, label: "Confermato" },
        { value: 10, label: "In attesa di pagamento" },
        { value: 25, label: "Approvazione" },
        { value: 50, label: "Annullato" },
        { value: 75, label: "Segnalato" },
        { value: 100, label: "Produttore" },
        { value: 200, label: "Consegato al magazzino" },
        { value: 205, label: "Pronto" },
        { value: 300, label: "Pronto per la consegna" },
        { value: 305, label: "Ritiro" },
        { value: 400, label: "Ritirato" },
        { value: 500, label: "Completato" },
        { value: 550, label: "Pagato" },
        { value: 600, label: "Errore" },
      ]),
      // Flags: new DataManager([
      //   { value: 1, label: this.$t("models.ppm.flags.options1") },
      //   { value: 2, label: this.$t("models.ppm.flags.option2") },
      //   { value: 3, label: this.$t("models.ppm.flags.option3") },
      //   { value: 4, label: this.$t("models.ppm.flags.option4") },
      //   { value: 5, label: this.$t("models.ppm.flags.option5") },
      //   { value: 6, label: this.$t("models.ppm.flags.option6") },
      // ]),

      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
      dtFormat: { type: "dateTime", format: "dd/MM/yyyy" },
      uTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.user.image_thumb;
              },
              name: function () {
                return (
                  this.data.user.first_name + " " + this.data.user.last_name
                );
              },
            },
          }),
        };
      },
      fTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}<br>
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.family.image_thumb;
              },
              name: function () {
                return this.data.family.name;
              },
            },
          }),
        };
      },
      mTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.market.role.image_thumb;
              },
              name: function () {
                return this.data.market.role.name;
              },
            },
          }),
        };
      },
      iTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.ddmi.island.role.image_thumb;
              },
              name: function () {
                return this.data.ddmi.island.role.name;
              },
            },
          }),
        };
      },
      sTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `
        <CProgress :max="max" class="mb-3">
          <CProgressBar color="secondary" :value="create" animated show-percentage/>
          <CProgressBar color="success" :value="producer" animated show-percentage/>
          <CProgressBar color="danger" :value="market" animated show-percentage/>
          <CProgressBar color="info" :value="delivery" animated show-percentage/>
          <CProgressBar color="success" :value="success" show-percentage/>
          <CProgressBar color="warning" :value="alert" show-percentage/>
          <CProgressBar color="danger" :value="deleted" show-percentage/>
        </CProgress>
        `,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              create: function () {
                let status = this.data.view.tot_status;
                return status["0"] || 0 + status["10"] || 0 + status["25"] || 0;
              },
              producer: function () {
                return this.data.view.tot_status["100"] || 0;
              },
              market: function () {
                let status = this.data.view.tot_status;
                return status["200"] || 0 + status["205"] || 0;
              },
              delivery: function () {
                let status = this.data.view.tot_status;
                return status["300"] || 0 + status["305"] || 0;
              },
              success: function () {
                return this.data.view.tot_status["500"] || 0;
              },
              alert: function () {
                return this.data.view.tot_status["75"] || 0;
              },
              deleted: function () {
                return this.data.view.tot_status["50"] || 0;
              },
              max: function () {
                return this.data.view.tot_prods || 0;
              },
            },
          }),
        };
      },
      // ppTemplate: function () {
      //     return { template : Vue.component('columnTemplate',{
      //        template: `<div class="image">
      //               <img v-if="image" :src="image" :alt="name"/> {{name}}
      //           </div>`,
      //           data: function() {
      //               return {
      //                   data: {}
      //               }
      //           },
      //           computed: {
      //               image: function() {
      //                   return this.data.ppl.productproducer.image_thumb
      //               },
      //               name: function() {
      //                   return this.data.ppl.productproducer.translations[this.$i18n.locale].name;
      //               }
      //           }
      //     })}
      // },
      // pplTemplate: function () {
      //     return { template : Vue.component('columnTemplate',{
      //        template: `<div class="image">
      //               <img v-if="image" :src="image" :alt="name"/> {{name}}
      //           </div>`,
      //           data: function() {
      //               return {
      //                   data: {}
      //               }
      //           },
      //           computed: {
      //               image: function() {
      //                   return this.data.ppl.image_thumb
      //               },
      //               name: function() {
      //                   return this.data.ppl.translations[this.$i18n.locale].name;
      //               }
      //           }
      //     })}
      // },
      // mrgTemplate: function () {
      //     return { template : Vue.component('columnTemplate',{
      //        template: `<div>
      //               {{mrg.toFixed(2)}}<br>
      //               <small>{{mrg_p.toFixed(0)}}%</small>
      //           </div>`,
      //           data: function() {
      //               return {
      //                   data: {}
      //               }
      //           },
      //           computed: {
      //               mrg: function() {
      //                   return this.data.ppm.price-this.data.ppl.price
      //               },
      //               mrg_p: function() {
      //                   return this.mrg*100/this.data.ppm.price
      //               }
      //           }
      //     })}
      // },
    };
  },
});
</script>
